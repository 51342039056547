exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-themen-und-gedanken-tsx": () => import("./../../../src/pages/themen-und-gedanken.tsx" /* webpackChunkName: "component---src-pages-themen-und-gedanken-tsx" */),
  "component---src-pages-typegen-tsx": () => import("./../../../src/pages/typegen.tsx" /* webpackChunkName: "component---src-pages-typegen-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-kurse-tsx": () => import("./../../../src/templates/kurse.tsx" /* webpackChunkName: "component---src-templates-kurse-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

